.fade-enter {
    opacity: 0;
    transform: translateY(20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}